import React from "react";
import { Review } from "./Review";

import GermanReviews from "../../lang/de/reviews.json";
import DutchReviews from "../../lang/nl/reviews.json";
import FrenchReviews from "../../lang/fr/reviews.json";
import EnglishReviews from "../../lang/en/reviews.json";
import SwissReviews from "../../lang/ch/reviews.json";
import SpanishReviews from "../../lang/es/reviews.json";
import { Box, Text } from "@chakra-ui/react";
import { FormattedMessage } from "react-intl";
import moment from "moment";

export const Reviews = (props) => {
  let reviews = null;

  switch (props.country) {
    case "nl":
      reviews = DutchReviews;
      break;
    case "fr":
      reviews = FrenchReviews;
      break;
    case "en":
      reviews = EnglishReviews;
      break;
    case "ch":
      reviews = SwissReviews;
      break;
    case "es":
      reviews = SpanishReviews;
      break;
    default:
      reviews = GermanReviews;
      break;
  }

  let lastGeneratedDate = new Date();

  const reviewsContent = reviews.map((review, index) => {
    const generateReviewDate = () => {
      let daysToDeduct = Math.floor(Math.random() * 4);
      lastGeneratedDate.setDate(lastGeneratedDate.getDate() - daysToDeduct);

      return moment(lastGeneratedDate).format("DD.MM.yyyy");
    };
    return (
      <Review
        key={"review-" + index}
        author={review.author}
        date={review.date.replace("{getDateRandomly}", generateReviewDate())}
        score={review.score}
        review={review.review}
      />
    );
  });

  const totalScore = (reviews) => {
    let totalScore = 0;

    reviews.forEach((review) => {
      totalScore += review.score;
    });

    let avgRating = Math.round((totalScore / reviews.length) * 100) / 100;

    return avgRating.toFixed(1);
  };

  return (
    <>
      <Box align="center">
        <Text fontSize="3xl">
          <FormattedMessage id="complete.product.review.headline" />
        </Text>
        <Text fontSize="6xl">{totalScore(reviews)}</Text>
      </Box>

      {reviewsContent}
    </>
  );
};
