import {Box, SimpleGrid, Text, useRadioGroup} from "@chakra-ui/react";
import RadioCard from "../../components/radio-card/RadioCard";
import Step from "../../components/step/Step";
import {useNavigate} from "react-router-dom";

import habitsImage1 from '../../assets/step1/habits1.png';
import habitsImage2 from '../../assets/step1/habits2.png';
import habitsImage3 from '../../assets/step1/habits3.png';
import {useIntl} from "react-intl";

function Step1() {
    let navigate = useNavigate();
    const intl = useIntl();

    const {getRootProps, getRadioProps} = useRadioGroup({
        name: 'q1',
        onChange: () => {
            navigate('../step2', {replace: true});
        }
    });

    const options = [
        {
            'id': 'a1',
            'icon': habitsImage1,
            'text': intl.formatMessage({id: 'step1.option1'})
        },
        {
            'id': 'a2',
            'icon': habitsImage2,
            'text': intl.formatMessage({id: 'step1.option2'})
        },
        {
            'id': 'a3',
            'icon': habitsImage3,
            'text': intl.formatMessage({id: 'step1.option3'})
        }
    ];

    const group = getRootProps()

    return (
        <Step heading={intl.formatMessage({id: 'step1.headline'})}
              currentStep={1}
              backLink={'/'}>
            <SimpleGrid mt={5} spacing={2} columns={1} {...group}>
                {options.map((value) => {
                    const radio = getRadioProps({'value': value.id})
                    return (
                        <RadioCard key={value.id} {...radio}>
                            <Box display={'flex'} alignItems={'center'}>
                                <Box mr={5}>
                                    <img height={100} width={100} src={value.icon} alt=''/>
                                </Box>
                                <Text fontSize={'lg'} fontWeight={'bold'} mt={3}>{value.text}</Text>
                            </Box>
                        </RadioCard>
                    )
                })}
            </SimpleGrid>
        </Step>
    );
}

export default Step1;
