import * as React from "react";
import { ChakraProvider } from "@chakra-ui/react";
import { MemoryRouter, Route, Routes } from "react-router-dom";
import moment from "moment";
import "moment/locale/de";
import "moment/locale/nl";
import "moment/locale/fr";
import "moment/locale/es";
import "moment/locale/en-gb";
import { IntlProvider } from "react-intl";
import German from "./lang/de.json";
import Dutch from "./lang/nl.json";
import French from "./lang/fr.json";
import English from "./lang/en.json";
import Spanish from "./lang/es.json";

import ChakraTheme from "./templates/Chakra";
import BplusTemplate from "./templates/Bplus";

import Home from "./pages/home/Home";
import Step1 from "./pages/step1/Step1";
import Step2 from "./pages/step2/Step2";
import Step3 from "./pages/step3/Step3";
import Step4 from "./pages/step4/Step4";
import Step5 from "./pages/step5/Step5";
import Step6 from "./pages/step6/Step6";
import Step7 from "./pages/step7/Step7";
import Step8 from "./pages/step8/Step8";
import Step9 from "./pages/step9/Step9";
import Step10 from "./pages/step10/Step10";
import Step11 from "./pages/step11/Step11";
import Step12 from "./pages/step12/Step12";
import Loader from "./pages/loader/Loader";
import Complete from "./pages/complete/Complete";

import success1 from "./assets/step4/success4.jpg";
import success2 from "./assets/step4/success1.jpg";
import success3 from "./assets/step9/success1.jpg";
import useImagePreloader from "./hooks/UseImagePreloader";

function App() {
  const currentUrl = window.location.toString();

  const preloadSrcList = [success1, success2, success3];

  useImagePreloader(preloadSrcList);

  let messages = German;
  let locale = "de";
  moment.locale("de");

  switch (true) {
    case currentUrl.search("/nl") !== -1:
      messages = Dutch;
      locale = "nl";
      moment.locale("nl");
      break;

    case currentUrl.search("/fr") !== -1:
      messages = French;
      locale = "fr";
      moment.locale("fr");
      break;
    case currentUrl.search("/de") !== -1:
      messages = German;
      locale = "de";
      moment.locale("de");
      break;

    case currentUrl.search("/uk") !== -1:
      messages = English;
      locale = "en";
      moment.locale("en");
      break;

    case currentUrl.search("/es") !== -1:
      messages = Spanish;
      locale = "es";
      moment.locale("es");
      break;

    case currentUrl.search("/befr") !== -1:
      messages = French;
      locale = "fr";
      moment.locale("fr");
      break;

    case currentUrl.search("/benl") !== -1:
      messages = Dutch;
      locale = "nl";
      moment.locale("nl");
      break;

    case currentUrl.search("/lude") !== -1:
      break;

    case currentUrl.search("/lufr") !== -1:
      messages = French;
      locale = "fr";
      moment.locale("fr");
      break;

    case currentUrl.search("/at") !== -1:
      break;

    case currentUrl.search("/ch") !== -1:
      messages["step5.headline"] = "Anna aus Basel";
      messages["step10.headline"] = "Svenja aus Zürich";
      locale = "de-ch";
      break;

    default:
  }

  if (window.locale) {
    locale = window.locale;
    
    switch (locale) {
      case "nl":
        messages = Dutch;
        locale = "nl";
        moment.locale("nl");
        break;
  
      case "fr":
        messages = French;
        locale = "fr";
        moment.locale("fr");
        break;
      case "de":
        messages = German;
        locale = "de";
        moment.locale("de");
        break;
  
      case "uk":
        messages = English;
        locale = "en";
        moment.locale("en");
        break;
  
      case "es":
        messages = Spanish;
        locale = "es";
        moment.locale("es");
        break;
  
      case "befr":
        messages = French;
        locale = "fr";
        moment.locale("fr");
        break;
  
      case "benl":
        messages = Dutch;
        locale = "nl";
        moment.locale("nl");
        break;
  
      case "lude":
        break;
  
      case "lufr":
        messages = French;
        locale = "fr";
        moment.locale("fr");
        break;
  
      case "at":
        break;
  
      case "ch":
        messages["step5.headline"] = "Anna aus Basel";
        messages["step10.headline"] = "Svenja aus Zürich";
        locale = "de-ch";
        break;
  
      default:
    }
  }

  // document.title = messages.title + ' | Green Gummies®';

  return (
    <MemoryRouter>
      <ChakraProvider theme={ChakraTheme}>
        <IntlProvider locale={locale} messages={messages} defaultLocale="de">
          <BplusTemplate>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/step1" element={<Step1 />} />
              <Route path="/step2" element={<Step2 />} />
              <Route path="/step3" element={<Step3 />} />
              <Route path="/step4" element={<Step4 />} />
              <Route path="/step5" element={<Step5 />} />
              <Route path="/step6" element={<Step6 />} />
              <Route path="/step7" element={<Step7 />} />
              <Route path="/step8" element={<Step8 />} />
              <Route path="/step9" element={<Step9 />} />
              <Route path="/step10" element={<Step10 />} />
              <Route path="/step11" element={<Step11 />} />
              <Route path="/step12" element={<Step12 />} />
              <Route path="/loader" element={<Loader />} />
              <Route path="/complete" element={<Complete />} />
            </Routes>
          </BplusTemplate>
        </IntlProvider>
      </ChakraProvider>
    </MemoryRouter>
  );
}

export default App;
